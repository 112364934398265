import React from "react";

import {
  Header,
  WhyYouNeed,
  RealTimeAlert,
  ProtectForest,
  TrustedUser,
  Footer,
  CookieConsent,
} from "../components";

const IndexPage = () => {
  return (
    <div>
      <Header></Header>
      <WhyYouNeed></WhyYouNeed>
      <RealTimeAlert></RealTimeAlert>
      <ProtectForest></ProtectForest>
      <TrustedUser></TrustedUser>
      <Footer></Footer>
      <CookieConsent></CookieConsent>
    </div>
  );
};

export default IndexPage;
